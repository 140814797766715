@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-size: 14px !important;
  font-family: 'Lato', sans-serif !important;
}
a {
  color: #cd3161 !important;
  text-decoration: none !important;
}
img {
  max-width: 100% !important;
}
.form-control {
  border-radius: 0 !important;
  min-height: 40px;
}
.form-control:focus {
  box-shadow: none !important;
}
.full-btn {
  width: 100%;
  display: block;
}
.btn:focus {
  box-shadow: none !important;
}
.btn-primary {
  background: #cd3161 !important;
  border: 1px solid #cd3161 !important;
  color: #fff !important;
}
.btn-primary:hover {
  background: #cd3161 !important;
  border: 1px solid #cd3161 !important;
  color: #fff !important;
}
.btn-primary:focus {
  box-shadow: none;
}
.btn-secondary {
  color: #fff !important;
  background-color: #3979da !important;
  border-color: #3979da !important;
}
.btn-secondary:hover {
  color: #fff !important;
  background-color: #3979da !important;
  border-color: #3979da !important;
}
.btn-white {
  background: #fff !important;
  border: 1px solid #fff !important;
  color: #000 !important;
}
.btn-white:hover {
  background: #fff !important;
  border: 1px solid #fff !important;
  color: #000 !important;
}
.btn-white:focus {
  box-shadow: none;
}
.text-right {
  text-align: right;
}
.header-section {
  box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
}
.top-header {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}
.welcome-text {
  margin: 0;
}
.top-menu-list {
  padding: 0;
  margin: 0;
  text-align: right;
}
.top-menu-list li {
  list-style: none;
  display: inline-block;
}
.top-menu-list li a {
  padding: 0 15px;
  display: block;
}
.top-menu-list li a {
  padding: 0 15px;
  display: block;
  color: #212529 !important;
}
.top-menu-list li:first-child a {
  border-right: 1px solid #ddd;
}
.top-menu-list li.registration-menu a {
  background: #cd3161;
  color: #fff !important;
  padding: 4px 14px;
}
.navbar-brand {
  width: 150px;
  padding: 0 !important;
}
.navbar-brand img {
  width: 100%;
}
.navbar {
  padding: 5px 0;
}
.navbar-nav {
  align-items: center;
}
.navbar .navbar-nav .nav-link {
  text-transform: uppercase;
  font-weight: 700;
}
.banner-section img {
  width: 100%;
}
.banner-section .item {
  height: 550px;
  background-size: cover;
  background-position: center;
}
.banner-content {
  max-width: 1140px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.banner-content h3 {
  max-width: 360px;
  color: #fff;
  font-weight: 700;
  font-size: 40px;
}
.banner-content h3 span {
  display: block;
  color: #cd3161;
}
.owl-theme .owl-nav {
  margin: 0 !important;
}
.owl-theme .owl-nav button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 30px !important;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.22) !important;
}
.owl-carousel .owl-nav button.owl-prev {
  left: 15px;
}
.owl-carousel .owl-nav button.owl-next {
  right: 15px;
}
.owl-theme .owl-nav button span {
  line-height: 0 !important;
  font-size: 50px;
  color: #fff;
}
.owl-theme .owl-dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
}
.owl-theme .owl-dots .owl-dot span {
  background: #ffffff !important;
  width: 7px !important;
  height: 7px !important;
}
.owl-theme .owl-dots .owl-dot.active span {
  background: #cd3161 !important;
  width: 30px !important;
}
.owl-theme .owl-dots .owl-dot:hover span {
  background: #cd3161 !important;
}
.webinfo-section {
  background: #cd3161;
  padding: 20px 0;
}
.short-webinfo-block {
  color: #fff;
  text-align: center;
  padding: 10px 0;
}
.short-webinfo-block h3 {
  font-size: 20px;
  margin: 0 0 5px;
}
.short-webinfo-block p {
  margin: 0;
}
.benifit-section {
  padding: 50px 0;
}
.heading-block {
  margin: 0 0 30px;
}
.heading-block h4 {
  color: #8A9DB0;
  font-size: 15px;
  margin: 0 0 5px;
}
.heading-block h3 {
  font-size: 25px;
  margin: 0;
}
.heading-block h3 span {
  color: #d9475c;
}
.experience-block {
  margin: 0 0 30px;
}
.benifitstript {
  background-image: url(/static/media/Top_new_Benefits.62123b7e.png);
  background-repeat: no-repeat;
}
.benifit1 {
  height: 53px;
  width: 60px;
  background-position: -23px -10px;
}
.benifit2 {
  height: 53px;
  width: 60px;
  background-position: -206px -10px;
}
.benifit3 {
  height: 53px;
  width: 60px;
  background-position: -121px -14px;
}
.experience-block h3 {
  font-size: 17px;
  padding-top: 10px;
  position: relative;
  margin-bottom: 20px;
  font-weight: 600;
}
.experience-block h3:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 50px;
  height: 2px;
  background: #cd3161;
}
.membership-section {
  background: #f8f9f9;
  padding: 40px 0;
}
.heading-block-center {
  text-align: center;
  max-width: 700px;
  margin: 0 auto 20px;
}
.heading-block-center h3 {
  font-size: 30px;
  margin: 0 0 5px;
}
.heading-block-center h3 span {
  color: #cd3161;
}
.membership-free-paid-blk {
  max-width: 750px;
  margin: 0 auto;
}
.membership-block {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
}
.membership-block h3 {
  font-size: 25px;
  margin: 10px 0;
  position: relative;
}
.membership-block h3:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 2px;
  background: #cd3161;
  top: -10px;
}
.membership-block ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}
.membership-block ul li {
  font-size: 15px;
  padding: 10px 0;
}
.membership-block ul li span {
  display: inline-block;
  width: calc(100% - 30px);
  padding-left: 5px;
  vertical-align: middle;
}
.materialIcons {
  background-image: url(/static/media/Material_Icons_Sprites_final.1d388d09.svg);
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
}
.pinktick {
  background-position: -271px -38px;
}
.greyCross {
  background-position: -288px -134px;
}
.whiteTick {
  background-position: -85px -37px;
}
.grey-text {
  color: #8A9DB0;
}
.paid-membership {
  background: #cd3161;
}
.paid-membership h3 {
  color: #fff;
}
.paid-membership h3:before {
  background: #fff;
}
.paid-membership ul li {
  color: #fff;
  padding: 15px 0;
}
.real-wedding-section {
  padding: 40px 0;
}
.successstory-blk img {
  width: 100%;
}
.successstory-blk {
  text-align: center;
  box-shadow: 0px 0px 5px #ddd;
  margin: 15px 0;
}
.successstory-cont {
  padding: 15px;
}
.successstory-blk h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 7px;
  color: #cd3161;
}
.successstory-blk p {
  margin: 0;
}
.readmore-btn {
  color: #fff !important;
  background: #cd3161;
  display: block;
  font-size: 15px;
  padding: 8px 15px;
  text-transform: uppercase;
}
.button-block {
  text-align: center;
  margin: 30px 0 0;
}
.viewmore-btn {
  background: #3979da;
  color: #fff !important;
  display: inline-block;
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 30px;
}
.upgrade-membership-section {
  background: #f8faf9;
  padding: 40px 0;
}
.upgrade-block {
  text-align: center;
  background: #fff;
  padding: 20px 40px;
  box-shadow: 0px 0px 6px #ddd;
  margin: 15px 0;
}
.upgrade-block h4 {
  font-size: 20px;
  padding: 20px 0 0;
  font-weight: 700;
}
.upgrade-block p {
  margin: 0;
  font-size: 17px;
}
.button-block p {
  padding-top: 20px;
}
.button-block p {
  padding-top: 20px;
  font-size: 18px;
}
footer {
  box-shadow: 0px 0px 5px #ededed;
}
.topfoot-section {
  padding: 40px 0;
}
.topfoot-blk h4 {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  padding: 0 0 15px;
  margin: 0 0 15px;
}
.topfoot-blk ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.topfoot-blk ul li {
  display: inline-block;
  padding: 5px 0;
  text-align: center;
}
.topfoot-blk ul li a {
  color: #4c4c4c !important;
  display: block;
  padding: 0 10px;
  border-right: 1px solid #ddd;
  line-height: 1.2;
}
.topfoot-blk ul li:last-child a {
  border-right: none;
}
.foot-menus-links-block {
  border-top: 1px solid #ddd;
  padding: 30px 0;
}
.foot-heading {
  font-size: 15px;
  font-weight: bold;
  margin: 0 0 15px;
}
.foot-cust-menu {
  padding: 0;
  list-style: none;
  margin: 0;
}
.foot-cust-menu li a {
  display: block;
  color: #4c4c4c !important;
  padding: 3px 0;
}
.copyright-footer {
  background: #000;
  padding: 10px 0;
}
.copyright-blk {
  color: #fff;
}
.copyright-blk.text-right {
  text-align: right;
}
.custom-login-modal .modal-dialog {
  max-width: 370px;
}
.heading-title-blk {
  text-align: center;
  position: relative;
}
.heading-title-blk .btn-close {
  position: absolute;
  right: -10px;
  top: -10px;
}
.heading-title-blk h3 {
  font-size: 22px;
  color: #000;
  margin: 0 0 20px;
}
.heading-title-blk p {
  font-size: 15px;
  margin: 0 0 10px;
}
.forgot-password-blk {
  text-align: right;
}
.or-block {
  text-align: center;
  padding: 20px 0;
  position: relative;
}
.or-block:before {
  position: absolute;
  border-bottom: 1px solid #ddd;
  bottom: 29px;
  left: 0;
  content: "";
  width: 100%;
}
.or-block span {
  background: #fff;
  position: relative;
  display: inline-block;
  padding: 0 6px;
}
.bottom-block {
  text-align: center;
}
.login-signup-section {
  background: #f9f9f9;
  padding: 40px 0;
}
.login-inner-block {
  background: #fff;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 7px #e7e7e7;
}
.login-bottom-blk {
  text-align: center;
}
.login-bottom-blk p, .login-bottom-blk h6 {
  margin: 20px 0 0;
}
.login-bottom-blk h5 {
  font-size: 15px;
  margin: 20px 0 0;
}
.innerpage-section {
  padding: 40px 0;
}
.signup-inner-block {
  background: #fff;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 7px #e7e7e7;
}
.heading-title-block {
  text-align: center;
  margin: 0 0 30px;
}
.heading-title-block h3 {
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 5px;
}
.heading-title-block p {
  font-size: 16px;
  margin: 0;
}
.input-text {
  font-size: 11px;
  color: #ababab;
}
#partitioned {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  min-width: 220px;
}
#partitioned:focus {
  outline: none;
}
#divInner{
  left: 0;
  position: sticky;
}
#divOuter{
  width: 190px; 
  overflow: hidden;
  margin: 20px auto;
}
.heading-title-blk h4 {
  font-size: 19px;
  font-weight: 600;
  margin: 0 0 5px;
}
.verification-new-section {
  max-width: 710px;
  margin: 0 auto;
}
.verification-layout {
  margin: 0 0 60px;
  position: relative;
}
.verification-layout:before {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 30px;
  height: 2px;
  background: #cd3161;
}
.verification-layout:last-child {
  margin: 0;
}
.verification-layout:last-child:before {
  content: none;
}
.verify-block h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px;
}
.verify-block p {
  font-size: 16px;
}
.verify-opt-block {
  display: flex;
}
.verify-opt-block .inline-button {
  border-radius: 0 !important;
}
.verify-opt-block {
  display: flex;
  margin: 0 0 15px;
}
.verify-block h6 {
  font-size: 15px;
  margin: 0;
}
.verification-image-blk {
  text-align: center;
}
.verification-image-blk img {
  max-width: 180px;
  margin: 0 0 20px;
}
.verification-image-blk h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px;
}
.verification-image-blk p {
  font-size: 15px;
}
.verify-button-blk {
  margin: 20px 0 0;
  text-align: right;
}
.verify-button-blk .btn-primary {
  width: 180px;
}
.user-image {
  width: 40px;
  height: 40px;
  background: #ddd;
  border: 1px solid #ddd;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.user-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.user-dropdown-block .dropdown-toggle::after {
  content: none !important;
}
.navbar-nav .dropdown-menu {
  left: auto !important;
  right: 0 !important;
}
.notification-header {
  background: #cd3161;
  border: 1px solid #cd3161;
  text-align: center;
  line-height: 24px;
}
.user-image i {
  font-size: 18px;
  color: #fff;
}
.navbar-nav .dropdown-menu .dropdown-item {
  color: #161616 !important;
  font-size: 14px;
}
.chat-header-block {
  width: 40px;
  height: 40px;
  background: #373737;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  margin-left: 10px;
  margin-right: 10px;
}
.chat-header-block i {
  font-size: 18px;
  color: #fff;
}
.profile-info-section {
  padding: 40px 0;
}
.profile-home-inner-block {
  background-image: url(/static/media/bg-1.48a732d2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 50px 150px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

}
.profile-home-inner-block:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(205, 49, 97, 0.54);
  top: 0;
  left: 0;
  position: absolute;
}
.profile-img-blk {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background: #ddd;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}
.profile-img-blk img {
  width: 100%;
}
.profile-content-block {
  width: calc(100% - 210px);
  display: inline-block;
  padding: 0 0 0 40px;
  vertical-align: top;
  position: relative;
  color: #fff;
}
.profile-content-block h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 20px;
  padding: 0 0 15px;
}
.profile-content-block h3:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background: #fff;
}
.profile-content-block span {
  padding: 0 15px;
  font-size: 90%;
}
.profile-content-block p {
  font-size: 15px;
  margin: 0 0 8px;
}
.add-details-profile {
  margin: 25px 0 0;
}
.showcase-block {
  background: #fff;
  box-shadow: 0px 0px 8px #efefef;
  padding: 15px;
  margin: 15px 0;
}
.showcase-block i {
  font-size: 30px;
  color: #cd3161;
  width: 35px;
  display: inline-block;
  vertical-align: top;
}
.showcase-content {
  width: calc(100% - 40px);
  display: inline-block;
  vertical-align: top;
  text-align: right;
}
.showcase-content h4 {
  font-size: 33px;
  font-weight: 600;
  line-height: 23px;
  margin: 0 0 10px;
}
.showcase-content p {
  margin: 0;
  font-size: 16px;
  color: #7c8383;
}
.lets-started-section {
  padding: 0 0 40px;
}
.match-profile-img {
  position: relative;
}
.match-profile-img img {
  width: 100%;
}
.match-profile-block {
  background: #f8f9f9;
  border-bottom: 2px solid #3979da;
}
.match-profile-block a {
  color: inherit !important;
}
.match-profile-cont {
  text-align: center;
  padding: 15px;
}
.match-profile-cont h3 {
  font-size: 17px;
  font-weight: 600;
  margin: 0;
}
.match-profile-cont p {
  margin: 0;
}
.owl-custom-slider .owl-dots {
  display: none;
}
.matches-slider-section {
  padding: 0 0 50px;
}
.matches-slider-section h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 20px;
}
.matches-slider-section h4 span {
  color: #cd3161;
}
.user-dashboard-section {
  padding: 40px;
}
.profile-block-nei img {
  width: 100%;
}
.profile-block-nei {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  background: #ddd;
  margin: 0 auto;
}
.profile-block-withedit {
  text-align: center;
}
.dashboard-sidebar {
  box-shadow: 0px 0px 8px #ebebeb;
}
.user-dashboard-profile-block {
  padding: 30px;
  border-bottom: 1px solid #ddd;
}
.profile-img-inner-bk {
  position: relative;
  width: 150px;
  margin: 0 auto;
}
.profile-block-edit {
  position: absolute;
  bottom: 0;
  width: 35px;
  height: 35px;
  background: #deebff;
  border-radius: 50%;
  line-height: 33px;
  right: 4px;
  text-align: center;
}
.profile-block-edit a {
  display: block;
  color: #000 !important;
  text-align: center;
}
.user-dashbaord-menu-list {
  padding: 30px 20px;
}
.user-progress p {
  margin: 0 0 5px;
  text-transform: uppercase;
}
.user-progress .progress {
  height: 5px;
  border-radius: 10px;
}
.dashboard-menu-list {
  padding: 0;
  list-style: none;
  margin: 20px 0 0;
}
.dashboard-menu-list li {
  border-bottom: 1px solid #ddd;
}
.dashboard-menu-list li:last-child {
  border-bottom: none;
}
.dashboard-menu-list li a {
  display: block;
  color: #2a2a2a !important;
  padding: 10px 0;
  transition: 0.3s;
}
.dashboard-menu-list li a.active, .dashboard-menu-list li a:hover {
  background: #e9ecef;
  padding: 10px 15px;
}
.profile-blk-content {
  margin: 15px 0 0;
}
.profile-blk-content h4 {
  font-size: 17px;
  font-weight: 600;
  margin: 0 0 5px;
}
.profile-blk-content p {
  font-size: 15px;
  margin: 0;
}
.dashboad-main-block {
  box-shadow: 0px 0px 8px #ebebeb;
  padding: 20px;
}
.dashboad-main-block h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboad-main-block h3 a {
  font-size: 14px;
  background: #cd3161;
  color: #fff !important;
  padding: 6px 20px;
  border-radius: 4px;
}
.members-slider-dash h4 {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin: 0 0 15px;
}
.members-slider-dash {
  margin: 0 0 20px;
}
.showcase-block-dash {
  box-shadow: 0px 0px 3px #f1f1f1;
  padding: 10px;
  background: #f5faff;
  margin: 0 0 20px;
}
.showcase-block-dash h4 {
  font-size: 20px;
  font-weight: 800;
  color: #cd3161;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showcase-block-dash h4 i {
  color: #ddd;
}
.showcase-block-dash p {
  text-align: center;
  margin: 0;
}
.custom-view-block h5 {
  font-size: 14px;
  margin: 5px 0;
}
.custom-view-block h5 b {
  display: inline-block;
  vertical-align: top;
  width: 130px;
}
.custom-view-block h5 span {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 135px);
}
.custom-view-block h4 {
  font-size: 17px;
  font-weight: 700;
  margin: 0 0 15px;
}
.custom-view-block {
  margin: 20px 0 0;
}
.css-1s2u09g-control {
  border-radius: 0 !important;
  min-height: 40px;
}
.about-image-blk img {
  width: 100%;
  border-radius: 10px;
  border-left: 5px solid #cd3161;
}
.about-section {
  padding: 40px 0 0;
}
.about-cont-blk .heading-block {
  margin: 0 0 20px;
}
.heading-block-left {
  text-align: left;
  margin: 0;
}
.mission-promis-block {
  margin: 20px 0 0;
}
.ourmission-block h5 {
  font-size: 17px;
  font-weight: 600;
  margin: 0 0 5px;
}
.ourmission-block p {
  margin: 0;
}
.ourmission-block {
  margin: 15px 0 0;
}
.contact-section {
  padding: 40px 0 30px;
}
.contact-info-blk p b {
  font-weight: normal;
  width: calc(100% - 40px);
  vertical-align: middle;
  display: inline-block;
  padding-left: 10px;
}
.contact-info-blk p i {
  width: 30px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  background: #cd3161;
  color: #fff;
  line-height: 30px;
  border-radius: 50%;
}
.contact-info-blk h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 15px;
}
.branches h5 {
  font-size: 16px;
  font-weight: 700;
}
.branches {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 0 0 30px;
}
.branches p:last-child {
  margin: 0;
}
.contact-form-blk h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 15px;
}
.previewprofile-blk {
  margin: 0 0 20px;
  border: 2px dashed #959595;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.upload-profileimg-blk input {
  min-height: 35px !important;
}
.upload-profileimg-blk .btn-primary {
  display: inline-block;
  width: 80px;
  vertical-align: middle;
  height: 38px;
  margin-left: 10px;
  border-radius: 0;
}
.upload-profileimg-blk .upload-file-blk {
  width: calc(100% - 90px);
  display: inline-block;
  vertical-align: middle;
}
.uploadmore-photos {
  margin: 30px 0 0;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}
.preview-other-profile-blk {
  margin: 30px 0 10px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #ddd;
}
.preview-other-profile-blk .trash-photo-btn {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #ca0202;
  color: #fff !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
}
.faq-section {
  padding: 40px 0;
}
.accordion-button:not(.collapsed) {
  color: #000 !important;
  background: #f9f9f9 !important;
  font-size: 18px !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.testimonial-section {
  padding: 40px 0;
}
.testi-cont {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}
.testi-img img {
  width: 60px !important;
  height: 60px;
  border-radius: 50%;
}
.testi-img {
  width: 60px;
  display: inline-block;
  vertical-align: middle;
}
.testi-auth {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 65px);
  padding-left: 15px;
}
.testi-auth h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.testi-auth p {
  margin: 0;
}
.testi-img-auth {
  margin: 20px 0;
}
.testimonial-block .owl-nav {
  position: absolute;
  right: 0;
  bottom: 30px;
}
.testimonial-block .owl-theme .owl-nav button {
  position: static;
  top: 50%;
  -webkit-transform: inherit;
          transform: inherit;
  font-size: 21px !important;
  width: 30px;
  height: 30px;
  background: #cd3161 !important;
  margin: 0 2px;
}
.testimonial-block .owl-theme .owl-nav button span {
  line-height: 0 !important;
  font-size: 35px;
  color: #fff;
}
.testi-cont {
  font-size: 17px;
  font-style: italic;
}
.membership-heading {
  text-align: center;
  margin: 0 0 30px;
}
.membership-heading h1 {
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 5px;
}
.membership-heading p {
  font-size: 16px;
  margin: 0;
}
.membership-heading h4 {
  font-size: 20px;
  margin: 10px 0 0;
}
.membership-main-block {
  background: #fff;
  box-shadow: 0px 0px 10px #eaeaea;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 15px 0;
}
.memhead-blk {
  padding: 20px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}
.memhead-blk h4 {
  font-size: 16px;
  margin: 0 0 10px;
}
.memhead-blk h5 {
  font-size: 17px;
  margin: 0 0 10px;
  color: #36a20f;
  font-weight: bold;
}
.memhead-blk h5 span {
  color: #ddd;
  text-decoration: line-through;
  padding-left: 5px;
}
.memhead-blk h3 {
  font-size: 35px;
  font-weight: bold;
  margin: 0;
}
.memhead-blk p {
  font-size: 13px;
  margin: 0 0 20px;
}
.memhead-blk .btn-primary {
  padding: 6px 30px;
  border-radius: 30px;
}
.membership-main-block ul {
  list-style: none;
  padding: 5px 0;
  margin: 0;
}
.membership-main-block ul li {
  text-align: center;
  padding: 12px 20px;
  border-bottom: 1px dashed #ddd;
  font-size: 15px;
}
.membership-main-block ul li:last-child {
  border-bottom: none;
}
.featuremem-blk {
  position: absolute;
  background: #e00;
  color: #fff;
  font-weight: bold;
  padding: 5px 50px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: -40px;
  top: 18px;
}
.custom-page-heading {
  text-align: center;
  margin: 0 0 30px;
}
.custom-page-heading h1 {
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 5px;
}
.custom-page-heading p {
  font-size: 16px;
  margin: 0;
}
.success-heading {
  margin: 0 0 25px;
}
.success-heading h3 {
  font-size: 22px;
  font-weight: bold;
  margin: 0 0 5px;
}
.success-heading p {
  margin: 0;
  font-size: 15px;
}
.write-story-btn-blk {
  margin: 0 0 25px;
  text-align: right;
}
.success-fullblk {
  box-shadow: 0px 0px 5px #ddd;
  border-radius: 10px;
  overflow: hidden;
}
.successstory-contblk {
  padding: 20px;
}
.successstory-contblk h4 {
  font-size: 22px;
  font-weight: bold;
  margin: 0 0 10px;
}
.successstory-contblk p {
  font-size: 15px;
}
.blog-main-block {
  margin: 0 0 30px;
}
.blog-aside h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 25px;
}
.recent-blogs-side {
  padding: 0;
  list-style: none;
}
.recent-blogs-side li a {
  color: inherit;
}
.recent-blogs-side li a {
  color: inherit !important;
}
.recent-blogs-side li img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.blogside-details {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 80px);
  padding-left: 15px;
}
.blogside-details h4 {
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 2px;
}
.blogside-details p {
  margin: 0 0 6px;
}
.blogside-details h5 {
  margin: 0;
  font-size: 13px;
  color: #979797;
}
.recent-blogs-side li {
  margin: 0 0 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}
.recent-blogs-side li:last-child {
  margin: 0 0 20px;
  border-bottom: none;
  padding-bottom: 20px;
}
.full-blog-details-blk h3 {
  font-size: 23px;
  font-weight: bold;
  margin: 0 0 5px;
}
.full-blog-details-blk h5 {
  margin: 0 0 10px;
  font-size: 13px;
  color: #979797;
}
.full-blog-details-blk img {
  margin: 0 0 10px;
  border-radius: 10px;
  width: 100%;
}
.full-blog-details-blk p {
  font-size: 16px;
}
.blog-comments {
  border-top: 1px solid #ddd;
  margin: 30px 0 0;
  padding-top: 20px;
}
.comments-count {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 20px;
}
.blog-comments .comment {
  margin-top: 30px;
  position: relative;
}
.blog-comments .comment .comment-img {
  margin-right: 14px;
  display: inline-block;
  vertical-align: top;
  width: 60px;
}
.blog-comments .comment .comment-img img {
  width: 60px;
  border-radius: 50%;
}
.comment-content {
  display: inline-block;
  width: calc(100% - 80px);
}
.blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
  color: #000;
  font-weight: bold;
}
.blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #565e49;
  margin-bottom: 5px;
}
.reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}
.reply-form p {
  font-size: 14px;
}
.complaint-blk {
  max-width: 650px;
  margin: 0 auto;
  box-shadow: 0px 0px 5px #ddd;
  padding: 20px 20px 10px;
  border-radius: 10px;
}
.member-shortdes-block {
  box-shadow: 0px 0px 5px #ddd;
  padding: 20px;
  border-radius: 5px;
}
.member-shortdes-block h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 5px;
}
.member-shortdes-block p {
  margin: 0 0 5px;
}
.member-shortdes-block p span {
  display: inline-block;
  padding: 0;
  color: #4f4f4f;
}
.member-shortdes-block p b {
  color: #ddd;
  padding: 0px 5px;
}
.member-img-block img {
  border-radius: 6px;
  box-shadow: -7px 7px 2px #bbb;
}
.member-img-block {
  padding: 10px 0px 10px 10px;
}
.member-main-btn {
  margin: 20px 0 0px;
}
.cust-member-blk {
  margin-top: 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
}
.cust-member-blk h3 {
  font-size: 18px;
  font-weight: bold;
  background: #eee;
  margin: -15px -15px 15px -15px;
  padding: 15px;
}
.verify-list-member li {
  padding: 3px 0;
  font-size: 15px;
}
.member-list-blkside {
  margin: 0;
  list-style: none;
  padding: 0;
}
.member-list-blkside li img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
}
.member-list-blkside li a {
  color: inherit !important;
}
.sidemember-cont {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 60px);
  padding-left: 15px;
}
.sidemember-cont h4 {
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 5px;
}
.sidemember-cont p {
  margin: 0;
}
.member-list-blkside li {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}
.member-list-blkside li:last-child {
  padding: 0;
  margin: 0;
  border: none;
}
.full-member-details-block {
  box-shadow: 0px 0px 5px #ddd;
  padding: 5px 20px 20px;
  border-radius: 5px;
  margin-top: 30px;
}
.matches-full-blk .member-img-block {
  padding: 0;
}
.matches-full-blk .member-img-block img {
  box-shadow: none;
  border-radius: 0;
}
.matches-full-blk .member-shortdes-block {
  box-shadow: none;
  padding: 0;
  border-radius:10px;
}
.matches-full-blk {
  box-shadow: 0px 0px 5px #ddd;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.matches-sidebar-blk {
  box-shadow: 0px 0px 5px #ddd;
  border-radius: 5px;
  margin-bottom: 30px;
}
.matches-sidebar-blk ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.matches-sidebar-blk ul li a {
  padding: 15px;
  display: block;
  border-bottom: 1px solid #ddd;
  color: #000 !important;
  font-size: 16px;
}
.matches-sidebar-blk ul li a.active, .matches-sidebar-blk ul li a:hover {
  background: #eaeaea;
}
.sms-button-block .full-btn {
  margin-bottom: 10px;
}
.inbox-searchblk {
  position: relative;
}
.inbox-searchblk .btn-search {
  background: #ced4da;
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  border-radius: 0;
  color: #000;
}
.form-check-input:focus {
  box-shadow: none !important;
}
.action-button-blk .btn {
  background: #ddd;
  font-size: 11px;
  padding: 5px;
  width: 25px;
  height: 25px;
  margin: 0 2px;
  line-height: 0;
}
.action-button-blk .btn-reply {
  background: #0d6efd;
  color: #fff;
}
.action-button-blk .btn-reply:hover {
  color: #fff;
}
.action-button-blk .btn-delete {
  background: #db0000;
  color: #fff;
}
.action-button-blk .btn-delete:hover {
  color: #fff;
}
.inbox-full-blk {
  box-shadow: 0px 0px 5px #ddd;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.inbox-header-blk {
  padding: 15px;
  background: #f9f9f9;
  margin: 0 0 10px;
}
.inboxtable-blk .table {
  margin: 0;
}
.inboxtable-blk .table td {
  vertical-align: middle;
}
.inboxtable-blk .table td, .inboxtable-blk .table th {
  padding: 12px 10px;
}
.inbox-sidebar-blk {
  box-shadow: 0px 0px 5px #ddd;
  border-radius: 5px;
  margin-bottom: 30px;
}
.inbox-sidebar-blk ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.inbox-sidebar-blk ul li a {
  padding: 15px;
  display: block;
  border-bottom: 1px solid #ddd;
  color: #000 !important;
  font-size: 16px;
}
.inbox-sidebar-blk ul li a.active, .inbox-sidebar-blk ul li a:hover {
  background: #eaeaea;
}
.inbox-sidebar-blk ul li a span {
  float: right;
  color: #cd3161;
}
.inbox-send-blk {
  padding: 20px;
}
.checkout-table-blk .table {
  margin: 0;
}
.checkout-table-blk th, .checkout-table-blk td {
  border: none !important;
  font-size: 16px;
  padding: 12px 20px !important;
}
.checkout-table-blk tfoot {
  border-top: 2px solid #ddd;
}
.checkout-table-blk tfoot tr th.text-right {
  border-bottom: 1px dashed #ddd !important;
}
.checkout-table-blk tfoot tr:last-child th.text-right {
  border-bottom: none !important;
}
.checkout-table-blk {
  box-shadow: 0px 0px 6px #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 0 30px;
}
.checkout-table-blk thead {
  background: #cd3161;
  color: #fff;
}
.checkout-button-blk {
  text-align: right;
}
.checkout-button-blk .btn-primary {
  min-width: 260px;
}
.notificationtable-blk {
  box-shadow: 0px 0px 5px #ddd;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.notificationtable-blk .table {
  margin: 0;
}
.notificationtable-blk .table td, .notificationtable-blk .table th {
  padding: 10px 15px;
}















@media only screen and (max-width: 991px) {
.welcome-text {
  display: none;
}
.top-menu-list li.hide-on-mobile {
  display: none;
}
.navbar-light .navbar-toggler {
  border: none;
}
.navbar-light .navbar-toggler:focus {
  box-shadow: none;
}
}











